export default function YouTube({ className = "w-5 fill-current" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 20"
      className={className}>
      <path d="M13.516-.001h.077l.305.002c1.511.008 5.747.053 8.763.287.521.064 1.657.07 2.671 1.202.8.864 1.06 2.824 1.06 2.824s.266 2.301.266 4.603l-.001 2.394c-.017 2.22-.266 4.365-.266 4.365s-.26 1.96-1.059 2.821c-1.014 1.132-2.15 1.139-2.67 1.205-.874.067-1.85.12-2.832.16l-.803.03c-2.403.083-4.688.101-5.44.105h-.14l-.052.001h-.076c-.16-.001-2.293-.024-4.515-.08l-.534-.014c-1.601-.044-3.17-.106-4.005-.192-.593-.118-1.924-.084-2.94-1.215-.798-.862-1.058-2.822-1.058-2.822S0 13.375 0 11.073V8.678c.018-2.219.267-4.364.267-4.364s.26-1.96 1.059-2.824C2.34.36 3.476.352 3.996.288c.635-.05 1.324-.09 2.03-.124L6.56.14l.535-.022A185.03 185.03 0 0112.76.001l.305-.002h.077zm-3.516 5v10l8.333-5-8.333-5z" />
    </svg>
  )
}
